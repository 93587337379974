<template>
	<div id="ImproveInfo">
		<div class="ImproveInfoBox">
			<div class="box">
				<h1>创建账号</h1>
				<ul class="steps">
					<template v-for="(item,i) in steps">
						<li :key="i" v-if="item.active<3">
							<div class="icon" v-if="item.active==2">
								0{{i+1}}
							</div>
							<div :class="item.active == 1?`icon active`:`icon`" v-if="item.active==1">
								<i class="el-icon-check" style="font-size:18px;"></i>
							</div>
							{{item.title}}
						</li>
					</template>
				</ul>

				<div class="StepBox">Step 2/{{steps.length}}</div>

				<p class="StepTitle">完善信息</p>

				<el-form :model="form" :rules="rules" ref="form" label-position="top">
					<el-row :gutter="40">
						<el-col :span="12">
							<el-form-item label="姓名：" prop="fullName">
								<el-input clearable v-model="form.fullName" placeholder="请输入用户姓名"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="身份证号：" prop="cardId">
								<el-input clearable v-model="form.cardId" placeholder="请输入身份证号"
									maxlength="18"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="银行卡号：" prop="bankNumber">
								<el-input clearable v-model="form.bankNumber" placeholder="请输入银行卡"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="开户行：" prop="bankName">
								<el-input clearable v-model="form.bankName" placeholder="请输入开户行"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="省份(直辖市)：" prop="province">
								<!-- <el-input clearable v-model="form.province" placeholder=""></el-input> -->
								<el-select v-model="form.province" placeholder="请选择所在省份(直辖市)" clearable
									@change="provinceChange">
									<el-option v-for="item in regionTree" :key="item.name" :label="item.name"
										:value="item.name">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="城市(地区)：" prop="city">
								<!-- <el-input clearable v-model="form.city" placeholder="请输入所在医院"></el-input> -->
								<el-select v-model="form.city" placeholder="请选择所在城市(地区)" clearable
									:disabled="!form.province">
									<el-option v-for="item in cityTree" :key="item.name" :label="item.name"
										:value="item.name">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="医院：" prop="hospital">
								<el-input clearable v-model="form.hospital" placeholder="请输入所在医院"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="科室：" prop="department">
								<!-- <el-input clearable v-model="form.department" placeholder="请输入所在科室"></el-input> -->
								<el-select v-model="form.department" placeholder="请选择所属科室" clearable filterable>
									<el-option v-for="item in department" :key="item.name" :label="item.name"
										:value="item.name">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="职称：" prop="title">
								<el-select v-model="form.title" placeholder="请选择职称" clearable filterable>
									<el-option v-for="item in title" :key="item.name" :label="item.name"
										:value="item.name">
									</el-option>
								</el-select>
								<!-- <el-input clearable v-model="form.title" placeholder="请选择职称"></el-input> -->
							</el-form-item>
						</el-col>

					</el-row>

					<el-form-item>
						<div class="options">
							<el-button class="bank" @click="bank()">返回</el-button>
							<el-button class="next" @click="onSubmit('form')">下一步</el-button>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		information,
		getDoctorInfo
	} from '@/request/api.js';
	import regionTree from '@/assets/regionTree.json';
	import department from '@/assets/department.json';
	import title from '@/assets/title.json';
	// let cardIdVerify = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
	export default {
		name: 'ImproveInfo',
		data() {
			return {
				checked: '',
				form: {
					fullName: '',
					hospital: '',
					department: '',
					title: '',
					cardId: '',
					bankNumber: '',
					bankName: '',
					province: '',
					city: ''
				},
				regionTree: '',
				cityTree: '',
				department: '',
				title: '',
				rules: {
					province: [{
						required: false,
						message: '请选择省份(直辖市)',
						trigger: 'change'
					}],
					city: [{
						required: false,
						message: '请选择城市(地区)',
						trigger: 'change'
					}],
					fullName: [{
						required: true,
						message: '请输入姓名',
						trigger: 'change'
					}],
					hospital: [{
						required: false,
						message: '请输入在职医院',
						trigger: 'change'
					}],
					department: [{
						required: false,
						message: '请填写科室',
						trigger: 'change'
					}],
					title: [{
						required: false,
						message: '请填写职务',
						trigger: 'change'
					}],
					cardId: [{
						required: false,
						message: '请输入身份证号',
						trigger: 'change'
					}, {
						validator: (rule, value, callback) => {
							if (!value) {
								callback();
							} else if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
								callback(new Error('请输入正确的身份证号!'));
							} else {
								callback();
							}
						},
						trigger: 'blur'
					}, {
						min: 15,
						max: 18,
						message: '请输入正确的身份证号!',
						trigger: 'blur'
					}],
					bankNumber: [{
						required: false,
						message: '请输入银行卡号',
						trigger: 'change'
					}],
					bankName: [{
						required: false,
						message: '请填写开户行',
						trigger: 'change'
					}, {
						validator: (rule, value, callback) => {
							if (!value) {
								callback();
							} else if (value.includes('支行')) {
								callback();
							} else {
								callback(new Error('开户行需要完善到支行!'));
							}
						},
						trigger: 'blur'
					}]
				},
				steps: [{
					title: '创建账号',
					active: 1
				}, {
					title: '上传认证',
					active: 1
				}, {
					title: '完善信息',
					active: 2
				}, {
					title: '参与项目',
					active: 3
				}]
			}
		},
		created() {
			this.regionTree = regionTree;
			this.department = department;
			this.title = title;
			this.getDoctorInfoFun();
		},
		mounted() {},
		methods: {

			getDoctorInfoFun() {
				getDoctorInfo().then(res => {
					if (res.code == 200) {
						this.form = res.data;
					}
				})
			},
			provinceChange(val) {
				this.form.city = '';
				this.regionTree.map(item => {
					if (item.name == val) {
						this.cityTree = item.city;
					}
				})
			},

			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						information(this.form).then(res => {
							if (res.code == 200) {
								this.$router.push({
									name: 'selectPro',
									query: this.$route.query
								})
								this.$notify({
									title: '提示',
									type: 'success',
									message: '创建账号成功，请继续完善用户信息',
									position: 'bottom-right'
								});
							} else {
								this.$notify({
									title: '错误',
									type: 'error',
									message: res.msg,
									position: 'bottom-right'
								});
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					};
				});
			},

			bank() {
				// this.$router.go(-1);
				localStorage.removeItem('regToken')
				this.$router.push({
					name: 'doctor'
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#ImproveInfo {
		padding: 20px;
		height: 100%;
		background: rgba(244, 249, 253, 1);

		.ImproveInfoBox {
			width: 600px;
			height: 100%;
			display: flex;
			overflow: hidden;
			border-radius: 20px;
			flex-direction: row;
			box-shadow: 0 0 20px rgba(0, 0, 0, .1);

			* {
				box-sizing: border-box;
			}

			.banner,
			.box {
				width: 600px;
				background-color: #fff;
			}

			.banner {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.logo {
					flex: 1;
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					padding: 40px 0 0 40px;

					img {
						height: 80px;
					}
				}

				.back {
					width: 400px;
					margin-bottom: 100px;
				}
			}

			.box {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding: 50px;
				overflow: hidden;
				overflow-y: auto;

				h1 {
					font-size: 20px;
					margin-bottom: 30px;
					color: #112950;
				}

				.steps {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					width: 100%;
					margin-bottom: 20px;

					li {
						display: flex;
						color: #112950;
						font-size: 16px;
						align-items: center;
						margin-right: 16px;

						.icon {
							width: 28px;
							height: 28px;
							background: #007AFF;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 5px;
							border-radius: 5px;
							color: #fff;
							font-size: 14px;
						}

						.active {
							background: #ECF3FE;
							color: #007AFF;
						}
					}
				}

				.StepBox {
					width: 100%;
					font-size: 13px;
					color: #112950;
					margin-bottom: 20px;
				}

				.StepTitle {
					width: 100%;
					font-size: 22px;
					color: #112950;
					border-bottom: 1px solid #E5E5E5;
					padding-bottom: 10px;
					margin-bottom: 20px;
				}

				::v-deep .el-form {
					width: 100%;

					.el-form-item__label {
						padding: 0;
						line-height: 1;
						color: #112950;
					}

					.el-input__inner {
						border: none;
						border-bottom: 1px solid #E5E5E5;
						border-radius: 0;
					}

					.el-input-group__append {
						background-color: #fff;
						border: none;
						border-bottom: 1px solid #E5E5E5;
					}

					.el-checkbox {
						display: flex;
						align-items: center;
					}

					.el-select {
						width: 100%;
					}

					.options {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;

						.el-button {
							width: 300px;
							height: 50px;
							background-color: #007AFF;
							color: #fff;
						}

						.next {
							background-color: #007AFF;
							color: #fff;
						}

						.bank {
							background-color: #fff;
							color: #FF5733;
							border: 1px solid #FF5733;
						}
					}
				}
			}
		}
	}
</style>